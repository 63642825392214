import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Container from 'components/Container'
import MedicalIcon from 'components/icons/Medical'
import { Title } from 'components/Page'
import HeaderIconContainer from 'components/icons/Container'
import ImplantIcon from 'components/icons/Implant'
import BondingIcon from 'components/icons/Bonding'
import CrownIcon from 'components/icons/Crown'
import BridgesIcon from 'components/icons/Bridges'
import VeneerIcon from 'components/icons/Veneer'
import DenturesIcon from 'components/icons/Dentures'
import XRayIcon from 'components/icons/XRay'
import FillingIcon from 'components/icons/Filling'
import HygieneIcon from 'components/icons/Hygiene'
import PeriodontalIcon from 'components/icons/Periodontal'
import WisdomTeethIcon from 'components/icons/WisdomTeeth'
import WhiteningIcon from 'components/icons/Whitening'
import RootCanalIcon from 'components/icons/RootCanal'

const TreatmentName = props => (
  <h3
    className="text-center md:text-left text-base font-bold uppercase my-4"
    {...props}
  />
)

const TreatmentDescription = props => (
  <p className="text-base leading-normal" {...props} />
)

const TreatmentsPage = () => (
  <Layout>
    <Container>
      <Helmet title="Oregon City Smiles - Treatments" />
      <Title>
        <HeaderIconContainer color="teal">
          <MedicalIcon color="white" size="2rem" fill="white" />
        </HeaderIconContainer>
        Treatments
      </Title>
      <div>
        <div className="flex flex-col sm:flex-col md:flex-row">
          <div className="flex flex-initial justify-center items-center p-8">
            <HeaderIconContainer color="lightTeal" size="4rem">
              <BondingIcon color="white" size="3.25rem" fill="white" />
            </HeaderIconContainer>
          </div>

          <div className="flex-1 mb-8">
            <TreatmentName>bonding</TreatmentName>
            <TreatmentDescription>
              Bonding is a quick, painless in-office procedure to repair cracks,
              chips, gaps between teeth or cover stained teeth to enhance your
              smile. Bonding can often be done without anesthesia if there is no
              decay or pulp exposure. The bonding material is developed in many
              shades allowing the dentist to accurately match the shade of your
              natural teeth. The composite resin forms a durable, stain
              resistant surface that is later smoothed and polished to give it a
              natural appearance and feel.
            </TreatmentDescription>
          </div>
        </div>

        <div className="flex flex-col sm:flex-col md:flex-row">
          <div className="flex flex-initial justify-center items-center p-8">
            <HeaderIconContainer color="lightTeal" size="4rem" opacity="0.9">
              <BridgesIcon color="white" fill="white" size="3rem" />
            </HeaderIconContainer>
          </div>

          <div className="flex-1 mb-8">
            <TreatmentName>bridges</TreatmentName>
            <TreatmentDescription>
              We will prescribe a bridge when one or more teeth are missing and
              there are healthy teeth on both sides of the open space. Bridges
              can be made from three types of material, solid gold for reliable
              long wear, porcelain fused to a gold base for strength and beauty,
              and high-strength metal-free porcelain for optimum cosmetic
              results. With proper care, your new bridge will increase your
              overall health and instill confidence for years to come.
            </TreatmentDescription>
          </div>
        </div>

        <div className="flex flex-col sm:flex-col md:flex-row">
          <div className="flex flex-initial justify-center items-center p-8">
            <HeaderIconContainer color="lightTeal" size="4rem" opacity="0.8">
              <CrownIcon color="white" size="2.5rem" fill="white" />
            </HeaderIconContainer>
          </div>
          <div className="flex-1 mb-8">
            <TreatmentName>crowns</TreatmentName>
            <TreatmentDescription>
              Crowns are prescribed when a tooth or teeth are too badly decayed,
              broken or cracked to be easily restored with a filling. Crowns are
              normally needed for teeth that have had root canals. Crowns are
              also placed on dental implants to replace missing teeth. Crowns
              can be made entirely of gold or porcelain fused to a gold
              substrate for strength and beauty. However, with new technology,
              crowns can be made entirely from high-strength ceramic and
              porcelain for beautiful cosmetic results.
              <br />
              With proper care, your new crown will increase your overall health
              and instill confidence for years to come.
            </TreatmentDescription>
          </div>
        </div>

        <div className="flex flex-col sm:flex-col md:flex-row">
          <div className="flex flex-initial justify-center items-center p-8">
            <HeaderIconContainer color="lightTeal" size="4rem" opacity="0.9">
              <DenturesIcon color="white" size="2.5rem" fill="white" />
            </HeaderIconContainer>
          </div>
          <div className="flex-1 mb-8">
            <TreatmentName>dentures</TreatmentName>
            <TreatmentDescription>
              If you have lost some or all of your natural teeth, dentures can
              be used to replace your missing teeth – improving your health as
              well as your appearance. Dentures prevent the sagging of facial
              muscles, which makes a person with no teeth look much older.
              Dentures also enable you to eat and speak normally, little things
              often taken for granted until natural teeth are lost.
              <br />
              Brush your gums, tongue, and palate every morning with a
              soft-bristled brush before you insert your dentures to stimulate
              circulation in your tissues and to help remove plaque. Regular
              dental checkups are needed so we can examine your mouth and
              dentures to ensure a healthy and comfortable fit.
            </TreatmentDescription>
          </div>
        </div>

        <div className="flex flex-col sm:flex-col md:flex-row">
          <div className="flex flex-initial justify-center items-center p-8">
            <HeaderIconContainer color="lightTeal" size="4rem">
              <XRayIcon color="white" size="2.5rem" fill="white" />
            </HeaderIconContainer>
          </div>

          <div className="flex-1 mb-8">
            <TreatmentName>x-rays</TreatmentName>
            <TreatmentDescription>
              We use state of the art digital X-rays and digital cameras to help
              with an accurate diagnosis of any concerns. Digital X-rays use
              less radiation to get a diagnostic picture of your teeth and they
              help the environment by eliminating the use of chemicals to
              process the film.
            </TreatmentDescription>
          </div>
        </div>

        <div className="flex flex-col sm:flex-col md:flex-row">
          <div className="flex flex-initial justify-center items-center p-8">
            <HeaderIconContainer color="lightTeal" size="4rem" opacity="0.9">
              <FillingIcon color="white" size="3rem" fill="white" />
            </HeaderIconContainer>
          </div>

          <div className="flex-1 mb-8">
            <TreatmentName>fillings</TreatmentName>
            <TreatmentDescription>
              For hundreds of years, dental fillings have been used to restore
              teeth. Dental fillings are placed in teeth where a dentist has had
              to remove tooth structure due to decay or fracture. In recent
              years filling materials have been substantially improved by many
              technological advancements. Although silver amalgam was the
              dominant choice years ago, today we have several other preferred
              options for tooth restoration including tooth colored composite
              filling materials and cementable resins and porcelains.
            </TreatmentDescription>
          </div>
        </div>

        <div className="flex flex-col sm:flex-col md:flex-row">
          <div className="flex flex-initial justify-center items-center p-8">
            <HeaderIconContainer color="lightTeal" size="4rem" opacity="0.8">
              <HygieneIcon color="white" size="2.5rem" fill="white" />
            </HeaderIconContainer>
          </div>

          <div className="flex-1 mb-8">
            <TreatmentName>hygenie</TreatmentName>
            <TreatmentDescription>
              The basis of sound dental care is routine cleaning and
              maintenance. We customize cleanings on the basis of your
              periodontal, (gums and bone), health. We utilize medicated gels
              along with prescription mouth rinses in problem areas if
              necessary. Ultrasonic scalers are sometimes used to make the
              procedure more comfortable and effective as well.
              <br />
              Sealants should be used as part of a child's total preventive
              dental care. Dental sealants are thin plastic coatings which are
              applied to the chewing surfaces of the molars (back teeth). Most
              tooth decay in children and teens occurs in these surfaces.
              Sealants cover the chewing surfaces to prevent decay. Applying
              sealants does not require drilling or removing tooth structure. It
              is an easy three-step process: We clean the tooth with a special
              toothpaste. A special cleansing liquid, on a tiny piece of cotton,
              is rubbed gently on the tooth and is washed off. Finally, the
              sealant is painted on the tooth. It takes about a minute for the
              sealant to form a protective shield. One sealant application can
              last for as long as 5 to 10 years. Sealants should be checked
              regularly, and reapplied if they are no longer in place.
              <br />
              Children and adults at high risk of dental decay may benefit from
              using additional fluoride products, including dietary supplements
              (for children who do not have adequate levels of fluoride in their
              drinking water), mouth rinses, and professionally applied gels and
              varnishes.
            </TreatmentDescription>
          </div>
        </div>

        <div className="flex flex-col sm:flex-col md:flex-row">
          <div className="flex flex-initial justify-center items-center p-8">
            <HeaderIconContainer color="lightTeal" size="4rem" opacity="0.9">
              <ImplantIcon size="2.5rem" color="white" fill="white" />
            </HeaderIconContainer>
          </div>

          <div className="flex-1 mb-8">
            <TreatmentName>implants</TreatmentName>
            <TreatmentDescription>
              When it comes to replacing one or more teeth in your mouth, a
              dental implant is often the treatment of choice and is also one of
              the most conservative treatments for tooth replacement. Implants
              are biocompatible posts that replace the roots of your missing
              teeth. They are physically and biologically designed to attach to
              your jaw bone and become the foundation for a natural looking
              restoration.
              <br />
              Your new tooth or teeth will have excellent stability and
              strength, and will look and feel like your natural teeth.
            </TreatmentDescription>
          </div>
        </div>

        <div className="flex flex-col sm:flex-col md:flex-row">
          <div className="flex flex-initial justify-center items-center p-8">
            <HeaderIconContainer color="lightTeal" size="4rem">
              <FillingIcon size="3rem" color="white" fill="white" />
            </HeaderIconContainer>
          </div>

          <div className="flex-1 mb-8">
            <TreatmentName>inlays</TreatmentName>
            <TreatmentDescription>
              Dental inlays and onlays are some of the strongest and most
              lifelike dental restorations available. After your mouth has been
              anesthetized we prepare the tooth in much the same way we would
              for a standard filling. When the tooth has been prepared to our
              satisfaction, an impression is made and sent to a dental lab. You
              will usually be required to wear a temporary filling until the
              restoration is fabricated. With proper care, your inlay or onlay
              will increase your overall health and instill confidence for years
              to come.
            </TreatmentDescription>
          </div>
        </div>

        <div className="flex flex-col sm:flex-col md:flex-row">
          <div className="flex flex-initial justify-center items-center p-8">
            <HeaderIconContainer color="lightTeal" size="4rem" opacity="0.9">
              <PeriodontalIcon color="white" size="2.25rem" fill="white" />
            </HeaderIconContainer>
          </div>

          <div className="flex-1 mb-8">
            <TreatmentName>periodontal</TreatmentName>
            <TreatmentDescription>
              Gum disease is usually caused by plaque, a sticky film of bacteria
              that constantly forms on teeth. If plaque is not removed with
              thorough daily brushing and cleaning between teeth, gums become
              irritated and inflamed. The irritated gum tissue can separate from
              the teeth and form spaces called pockets. Bacteria move into the
              pockets where they continue to promote irritation. Left untreated,
              the process can continue until the bone and other tooth-supporting
              tissues are destroyed. The early stage of periodontal disease is
              called gingivitis. It often results in gums that are red and
              swollen and may bleed easily.
            </TreatmentDescription>
          </div>
        </div>

        <div className="flex flex-col sm:flex-col md:flex-row">
          <div className="flex flex-initial justify-center items-center p-8">
            <HeaderIconContainer color="lightTeal" size="4rem" opacity="0.8">
              <RootCanalIcon color="white" size="2.25rem" fill="white" />
            </HeaderIconContainer>
          </div>

          <div className="flex-1 mb-8">
            <TreatmentName>root canal</TreatmentName>
            <TreatmentDescription>
              Endodontic treatment, more commonly known as root canal therapy,
              is necessary when the pulp of your tooth becomes inflamed or
              infected. The inflammation or infection can have a variety of
              causes such as deep decay, repeated dental procedures on your
              tooth, or a crack or chip in the tooth. With proper care, an
              Endodontically treated tooth will function normally for years to
              come.
            </TreatmentDescription>
          </div>
        </div>

        <div className="flex flex-col sm:flex-col md:flex-row">
          <div className="flex flex-initial justify-center items-center p-8">
            <HeaderIconContainer color="lightTeal" size="4rem" opacity="0.9">
              <VeneerIcon size="2.5rem" color="white" fill="white" />
            </HeaderIconContainer>
          </div>

          <div className="flex-1 mb-8">
            <TreatmentName>veneers</TreatmentName>
            <TreatmentDescription>
              Unlike a crown, which covers the entire tooth, a veneer is a thin
              shell that is placed over the front or visible parts of the tooth.
              There are two types of veneers, ceramic veneers that are produced
              by a dental laboratory and resin-based composite veneers. With
              proper care, your new veneers will greatly improve your appearance
              and increase your confidence for years to come.
            </TreatmentDescription>
          </div>
        </div>

        <div className="flex flex-col sm:flex-col md:flex-row">
          <div className="flex flex-initial justify-center items-center p-8">
            <HeaderIconContainer color="lightTeal" size="4rem">
              <WhiteningIcon color="white" size="2.5rem" fill="white" />
            </HeaderIconContainer>
          </div>

          <div className="flex-1 mb-8">
            <TreatmentName>whitening</TreatmentName>
            <TreatmentDescription>
              Everyone loves a bright, white smile. Today there are a variety of
              products and procedures available to help you improve the color of
              your teeth. Some whitening procedures are done in our office with
              one visit. Other whitening agents can even be purchased
              over-the-counter. However, over-the-counter whitening agents will
              not whiten your teeth as quickly or as effectively as those
              delivered by a dentist.
            </TreatmentDescription>
          </div>
        </div>

        <div className="flex flex-col sm:flex-col md:flex-row">
          <div className="flex flex-initial justify-center items-center p-8">
            <HeaderIconContainer color="lightTeal" size="4rem" opacity="0.9">
              <WisdomTeethIcon color="white" size="3rem" fill="white" />
            </HeaderIconContainer>
          </div>

          <div className="flex-1 mb-8">
            <TreatmentName>wisdom teeth</TreatmentName>
            <TreatmentDescription>
              Wisdom teeth, or third molars, are the last teeth to erupt in your
              mouth and are the teeth least needed for good oral health. Most
              people have four wisdom teeth that usually erupt through the gums
              in their late teens or early twenties. Sometimes wisdom teeth
              remain trapped in the jawbone under the gums because there is
              insufficient room for them to erupt.
              <br />
              Wisdom teeth can cause gum disease, crowding or other damage to
              adjacent teeth, decay (due to the inability of your toothbrush to
              keep the area clean), and bone destroying cysts. Pain and swelling
              are the most common symptoms of problem wisdom teeth. Whether you
              have obvious symptoms or not, it is important to diagnose existing
              or potential problems caused by your wisdom teeth. Removal is
              often the wisest decision for your health and well-being.
            </TreatmentDescription>
          </div>
        </div>
      </div>
    </Container>
  </Layout>
)

export default TreatmentsPage
