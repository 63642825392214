import React from 'react'
import IoniconSVG from './IoniconSVG'

const Medical = props => (
  <IoniconSVG
    fill="currentColor"
    viewBox="0 0 512 512"
    strokeWidth={0}
    {...props}
  >
    <title>medical</title>
    <path d="M351.9 256L460 193.6l-48-83.2-108 62.4V48h-96v124.8l-108-62.4-48 83.2L160.1 256 52 318.4l48 83.2 108-62.4V464h96V339.2l108 62.4 48-83.2z" />
  </IoniconSVG>
)

export default Medical
